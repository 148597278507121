<template>
    <div class="about">
      <h1>This is a user page</h1>
    </div>
  </template>

<script>
export default {
  name: "UsersView"
}
</script>

<style scoped>

</style>