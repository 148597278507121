<template>
    <div class="container-fluid">

<!-- Page Heading -->
<h1 class="mb-2 text-gray-800 text-left">Houses</h1>
<p class="mb-4 text-left">Listado de casas en la plataforma.</p>

<!-- DataTales Example -->


</div>
  </template>

<script>
import axios from 'axios';
export default {
  name: "HousesView",
  data() {
    return{
      housesArray: [],
      tableTitles:[



      ]
    }
  },
  methods:{
    // regresa todos los datos
    async getallHouses(){
      const result= await axios.get("http://localhost:3000/houses");
      this.housesArray = result.data
    }
  },
  created(){
    this.getallHouses();
  }
}
</script>

<style scoped>

</style>